import * as A from "../assets/images";

export const assigmentData = {
    title: 'fleet_holistic_fleet',
    text: 'fleet_holistic_features',
    imgSrc: A.fleetAssigment,
    buttonText: '',
    isExternalButtonLink: true
}

export const designedData = [
    {
        text: 'fleet_designed_fleet_managers',
        imgSrc: A.multipleCompanyOwners
    },
    {
        text: 'fleet_designed_it_leaders',
        imgSrc: A.carsharingCompanies
    },
    {
        text: 'fleet_designed_fleet_owners',
        imgSrc: A.carRentals
    },
]

export const usefulData = [
    {
        src: A.odometerIcon,
        title: 'useful_data_odometer',
    },
    {
        src: A.dtcIcon,
        title: 'useful_data_dtc',
    },
    {
        src: A.gpsIcon,
        title: 'useful_data_gps',
    },
    {
        src: A.accelerometerIcon,
        title: 'useful_data_accelerometer',
    },
    {
        src: A.fuelLevelIcon,
        title: 'useful_data_fuel_level',
    },
    {
        src: A.rpmIcon,
        title: 'useful_data_rpm',
    },
    {
        src: A.throttleIcon,
        title: 'useful_data_throttle',
    },
    {
        src: A.coolantTemperature,
        title: 'useful_data_coolant_temperature',
    },
    {
        src: A.engineLoad2Icon,
        title: 'useful_data_engine_load',
    },
];

export const whyMobdData = [
    {
        title: 'fleet_cases_fleet_managers',
        text: 'fleet_cases_powerful_solutions',
        imgSrc: A.whyMobdIndividuals1
    },
    {
        title: 'fleet_cases_it_leaders',
        text: 'fleet_cases_feel_confident',
        imgSrc: A.whyMobdIndividuals9
    },
    {
        title: 'fleet_cases_fleet_owners',
        text: 'fleet_cases_features',
        imgSrc: A.whyMobdIndividuals10
    },
]

export const pricingData = {
    price_per_device_annually: 'specific_pricing_fleet_price_per_device_annually',
    price_per_device_annually_currency: 'specific_pricing_fleet_price_per_device_annually_currency',
    price_per_device_monthly: 'specific_pricing_fleet_price_per_device_monthly',
    price_per_device_monthly_currency: 'specific_pricing_fleet_price_per_device_monthly_currency',
    price_unit: 'specific_pricing_fleet_price_unit',
    title: 'specific_price_fleet_title',
    subtitle: 'specific_price_fleet_fleet_license',
    topRow: {
        firstColumn: {
            dashboard: {
                title: 'specific_price_general_mobd_fleet_dashboard',
                items: ['specific_price_general_unlimited_users', 'specific_price_general_trips_log', 'specific_price_general_intelligent_analytics', 'specific_price_general_early_diagnostics', 'specific_price_general_accident_detection', 'specific_price_general_expense_management', 'specific_price_general_vehicle_timeline', 'specific_price_general_roles_and_permissions', 'specific_price_general_secured_api']
            },
        },
        secondColumn: {
            mobile: {
                title: 'specific_price_general_mobile_platforms',
                items: ['specific_price_general_trips_log', 'specific_price_general_early_diagnostics', 'specific_price_general_accident_detection', 'specific_price_general_expense_management', 'specific_price_general_hud', 'specific_price_general_vehicle_timeline', 'specific_price_general_role_based_access', 'specific_price_general_driver_score_exp']
            },
            additional: {
                title: 'specific_price_general_additional_features',
                items: ['specific_price_general_firmware_updates', 'specific_price_general_premium_support']
            }
        }
    },
    hardWareFirstColumn: ['specific_price_general_connectivity_via_mobile', 'specific_price_general_one_user_per_device', 'specific_price_general_trips_log', 'specific_price_general_driver_score'],
    hardwareSecondColumn: ['specific_price_general_dtc_alert', 'specific_price_general_hud', 'specific_price_general_vehicle_timeline', 'specific_price_general_manual_firmware']
};