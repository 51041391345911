import * as React from "react"
import Page from "../components/Page";
import {
    Copyright, Footer,
    Nav, Partners,
    SectionAssigment,
    SectionDesigned,
    SectionInstall,
    SectionSpecificPricing,
    SectionWhyMobd
} from "../components/partials";
import {assigmentData, designedData, whyMobdData, pricingData} from "../pagesData/fleet";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";

const FleetManagersPage = () => {
    const {t} = useTranslation();

    return (
        <Page>
            <SEO title={(t('page_title_fleet_managers'))} />
            <Nav/>
            <SectionAssigment {...assigmentData} />
            <SectionDesigned
                data={designedData}
            />
            <SectionWhyMobd
                title={t('use_cases')}
                data={whyMobdData}
            />
            {/*<SectionUsefulData usefulData={usefulData} />*/}
            <SectionSpecificPricing {...pricingData} />
            <SectionInstall/>
            {/*<SectionContact/>*/}
            <Footer />
            <Partners />
            <Copyright/>
        </Page>
    )
};

export default FleetManagersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;